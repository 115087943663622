import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'

import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getUser, getAllUserInfo } from 'store/Slices/user'

import { Dropdown, message, Table, Tooltip, Switch } from 'antd'
import { getAgeFromJumin, getGenderFromResidentNumber } from 'utils/utilCommon'

import { deleteProgramById } from 'api/program/program.api'
import { deleteAccountById } from 'api/accounts/accouint.api'
import { deleteContactById } from 'api/contact/contact.api'
import { deleteTutorById } from 'api/tutors/tutors.api'
import { deleteReportById, getReportListCount } from 'api/report/report.api'
import { deleteReportIssueById } from 'api/report/reportIssue.api'
import { deleteSurveyById } from 'api/survey/survey.api'

import InfoDialog from 'components/AntD/Dialog'
import DeleteModalContent from 'components/AntD/Dialog/deleteModalContent'
import FilterButton from 'components/AntD/Button/FilterButton'
import CheckBox from 'components/AntD/CheckBox/type2'
import Account from 'components/AntD/Dialog/Account'

import { FinishFilterBottomIcon } from 'assets/Icons'

import { ReactComponent as PageIcon } from '../../../assets/Icons/svg/Icon/36/icon-page.svg'
import { ReactComponent as AdminIcon } from '../../../assets/Icons/svg/Icon/36/icon-admin.svg'
import { ReactComponent as Attachment } from '../../../assets/Icons/svg/Icon/36/Attachment.svg'
import styles from './listView.module.scss'

const programRegisterColumns = [
    {
        title: '제목*',
        dataIndex: '제목*',
        key: '제목*',
    },
    {
        title: '유형*',
        dataIndex: '유형*',
        key: '유형*',
    },
    {
        title: '정규구분*',
        dataIndex: '정규구분*',
        key: '정규구분*',
    },
    {
        title: '형태*',
        dataIndex: '형태*',
        key: '형태*',
    },
    {
        title: '구분*',
        dataIndex: '구분*',
        key: '구분*',
    },
    {
        title: '학년*',
        dataIndex: '학년*',
        key: '학년*',
    },
    {
        title: '기관명*',
        dataIndex: '기관명*',
        key: '기관명*',
    },
    {
        title: '주소(시/도)*',
        dataIndex: '주소(시/도)*',
        key: '주소(시/도)*',
    },
    {
        title: '주소(시/군/구)*',
        dataIndex: '주소(시/군/구)*',
        key: '주소(시/군/구)*',
    },
    {
        title: '주소(읍/면/동)*',
        dataIndex: '주소(읍/면/동)*',
        key: '주소(읍/면/동)*',
    },
    {
        title: '교육 신청인원(명)*',
        dataIndex: '교육 신청인원(명)*',
        key: '교육 신청인원(명)*',
    },
    {
        title: '교육 시작일*',
        dataIndex: '교육 시작일*',
        key: '교육 시작일*',
    },
    {
        title: '교육 종료일*',
        dataIndex: '교육 종료일*',
        key: '교육 신청인원(명)*',
    },
    {
        title: '교육시간 (교시)*',
        dataIndex: '교육시간 (교시)*',
        key: '교육시간 (교시)*',
    },
    {
        title: '교육횟수 (회)*',
        dataIndex: '교육횟수 (회)*',
        key: '교육횟수 (회)*',
    },
    {
        title: '교육수료인원 (명)',
        dataIndex: '교육수료인원 (명)',
        key: '교육수료인원 (명)',
    },
    // {
    //     title: '단기 / 중기 / 장기*',
    //     dataIndex: '단기 / 중기 / 장기*',
    //     key: '단기 / 중기 / 장기*',
    // },
    {
        title: '주강사',
        dataIndex: '주강사',
        key: '주강사',
        render: items => {
            if (items) {
                if (!Array.isArray(items)) {
                    return <li>{typeof items === 'string' ? items : <span style={{ color: 'red' }}>{items}</span>}</li>
                }
                return items.map((item, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <li key={index}>
                        {typeof item === 'string' ? item : <span style={{ color: 'red' }}>{item.name}</span>}
                    </li>
                ))
            }
            return ''
        },
    },
    {
        title: '보조강사',
        dataIndex: '보조강사',
        key: '보조강사',
        render: items => {
            if (items) {
                if (!Array.isArray(items)) {
                    return <li>{typeof items === 'string' ? items : <span style={{ color: 'red' }}>{items}</span>}</li>
                }
                return items.map((item, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <li key={index}>
                        {typeof item === 'string' ? item : <span style={{ color: 'red' }}>{item.name}</span>}
                    </li>
                ))
            }
            return ''
        },
    },
    {
        title: '성명',
        dataIndex: '성명',
        key: '성명',
    },
    {
        title: '직위/직책',
        dataIndex: '직위/직책',
        key: '직위/직책',
    },
    {
        title: '대표번호',
        dataIndex: '대표번호',
        key: '대표번호',
    },
    {
        title: '휴대전화',
        dataIndex: '휴대전화',
        key: '휴대전화',
    },
    {
        title: '이메일',
        dataIndex: '이메일',
        key: '이메일',
    },
    {
        title: '수요처 특이사항',
        dataIndex: '수요처 특이사항',
        key: '수요처 특이사항',
    },
    {
        title: '권역',
        dataIndex: '권역',
        key: '권역',
    },
    {
        title: '학급',
        dataIndex: '학급',
        key: '학급',
    },
    {
        title: '교보재',
        dataIndex: '교보재',
        key: '교보재',
    },
    {
        title: '기관 컴퓨터 구비여부',
        dataIndex: '기관 컴퓨터 구비여부',
        key: '기관 컴퓨터 구비여부',
    },
    {
        title: '배정번호',
        dataIndex: '배정번호',
        key: '배정번호',
    },
    {
        title: '출석인원',
        dataIndex: '출석인원',
        key: '출석인원',
    },
    {
        title: '비고',
        dataIndex: '비고',
        key: '비고',
    },
]

const tutorsRegisterColumns = [
    {
        title: '신규강사 여부*',
        dataIndex: '신규강사 여부*',
        key: '신규강사 여부*',
        width: '135px',
    },
    {
        title: '재고용여부*',
        dataIndex: '재고용여부*',
        key: '재고용여부*',
        width: '115px',
    },
    {
        title: '청년*',
        dataIndex: '청년*',
        key: '청년*',
        width: '120px',
    },
    {
        title: '전문퇴직자*',
        dataIndex: '전문퇴직자*',
        key: '전문퇴직자*',
        width: '120px',
    },
    {
        title: '경력단절여성*',
        dataIndex: '경력단절여성*',
        key: '경력단절여성*',
        width: '140px',
    },
    // {
    //     title: '대상자 분류*',
    //     dataIndex: '대상자 분류*',
    //     key: '대상자 분류*',
    //     width: '120px',
    // },
    {
        title: '외국인*',
        dataIndex: '외국인*',
        key: '외국인*',
        width: '120px',
    },
    {
        title: '장애여부*',
        dataIndex: '장애여부*',
        width: '115px',
    },
    {
        title: '고급강사 여부*',
        dataIndex: '고급강사 여부*',
        key: '고급강사 여부*',
        width: '135px',
    },
    {
        title: '성명*',
        dataIndex: '성명*',
        key: '성명*',
        width: '120px',
        render: (item, record) => {
            return <span style={{ color: record.duplicated ? 'red' : '' }}>{item}</span>
        },
    },
    {
        title: '성별*',
        dataIndex: '성별*',
        key: '성별*',
        width: '120px',
    },
    {
        title: '주민등록번호*',
        dataIndex: '주민등록번호*',
        key: '주민등록번호*',
        width: '130px',
    },
    {
        title: '주소(시/도)*',
        dataIndex: '주소(시/도)*',
        key: '주소(시/도)*',
        width: '120px',
    },
    {
        title: '주소(시/군/구)*',
        dataIndex: '주소(시/군/구)*',
        key: '주소(시/군/구)*',
        width: '140px',
    },
    {
        title: '주소(읍/면/동)*',
        dataIndex: '주소(읍/면/동)*',
        key: '주소(읍/면/동)*',
        width: '140px',
    },
    {
        title: '연락처*',
        dataIndex: '연락처*',
        key: '연락처*',
        width: '140px',
    },
    {
        title: '이메일*',
        dataIndex: '이메일*',
        key: '이메일*',
        width: '130px',
    },
    {
        title: '기수 (시작년도)',
        dataIndex: '기수 (시작년도)',
        key: '기수 (시작년도)',
        width: '140px',
    },
    {
        title: '권역',
        dataIndex: '권역',
        key: '권역',
        width: '120px',
    },
    {
        title: '계약시작*',
        dataIndex: '계약시작*',
        key: '계약시작*',
        width: '110px',
    },
    {
        title: '계약만료*',
        dataIndex: '계약만료*',
        key: '계약만료*',
        width: '110px',
    },
    {
        title: '고용형태',
        dataIndex: '고용형태',
        key: '고용형태',
        width: '110px',
    },
    {
        title: '전공 (학과명)*',
        dataIndex: '전공 (학과명)*',
        key: '전공 (학과명)*',
        width: '130px',
    },
    {
        title: '최종학력*',
        dataIndex: '최종학력*',
        key: '최종학력*',
        width: '120px',
    },
    {
        title: 'SW 자격증*',
        dataIndex: 'SW 자격증*',
        key: 'SW 자격증*',
        width: '130px',
    },
    {
        title: 'SW 미래채움사업 민간자격 취득*',
        dataIndex: 'SW 미래채움사업 민간자격 취득*',
        key: 'SW 미래채움사업 민간자격 취득* ',
        width: '160px',
    },
    // {
    //     title: '',
    //     dataIndex: '민간자격 취득*',
    //     key: '민간자격 취득*',
    //     width: '135px',
    // },
    {
        title: '강의경험 유무*',
        dataIndex: '강의경험 유무*',
        key: '강의경험 유무*',
        width: '135px',
    },
    {
        title: '일반 강의 경력',
        dataIndex: '일반 강의 경력',
        key: '일반 강의 경력',
        width: '130px',
    },
    {
        title: 'SW 강의경력',
        dataIndex: 'SW 강의경력',
        key: 'SW 강의경력',
        width: '130px',
    },
    {
        title: '예금주',
        dataIndex: '예금주',
        key: '예금주',
        width: '110px',
    },
    {
        title: '입금은행',
        dataIndex: '입금은행',
        key: '입금은행',
        width: '120px',
    },
    {
        title: '계좌번호',
        dataIndex: '계좌번호',
        key: '계좌번호',
        width: '140px',
    },
    {
        title: '창업구분',
        dataIndex: '창업구분',
        key: '창업구분',
        width: '110px',
    },
    {
        title: '창업일자',
        dataIndex: '창업일자',
        key: '창업일자',
        width: '110px',
    },
    {
        title: '사업장형태',
        dataIndex: '사업장형태',
        key: '사업장형태',
        width: '130px',
    },
    {
        title: '홈텍스 확인현황',
        dataIndex: '홈텍스 확인현황',
        key: '홈텍스 확인현황',
        width: '140px',
    },
    {
        title: '사업장 상호',
        dataIndex: '사업장 상호',
        key: '사업장 상호',
        width: '130px',
    },
    {
        title: '사업자등록번호',
        dataIndex: '사업자등록번호',
        key: '사업자등록번호',
        width: '140px',
    },
    {
        title: '사업장주소',
        dataIndex: '사업장주소',
        key: '사업장주소',
        width: '130px',
    },
    {
        title: '위촉번호',
        dataIndex: '위촉번호',
        key: '위촉번호',
        width: '130px',
    },
    {
        title: '교원자격보유',
        dataIndex: '교원자격보유',
        key: '교원자격보유',
        width: '130px',
    },
    {
        title: '수화가능 여부',
        dataIndex: '수화가능 여부',
        key: '수화가능 여부',
        width: '130px',
    },
    {
        title: '보훈여부',
        dataIndex: '보훈여부',
        key: '보훈여부',
        width: '130px',
    },
    {
        title: '강사평가',
        dataIndex: '강사평가',
        key: '강사평가',
        width: '150px',
    },
    {
        title: '연속배치',
        dataIndex: '연속배치',
        key: '연속배치',
        width: '130px',
    },
    {
        title: '자차여부',
        dataIndex: '자차여부',
        key: '자차여부',
        width: '130px',
    },
    {
        title: '지역인재',
        dataIndex: '지역인재',
        key: '지역인재',
        width: '130px',
    },
    {
        title: '고유번호',
        dataIndex: '고유번호',
        key: '고유번호',
        width: '130px',
    },
    {
        title: '기타활동',
        dataIndex: '기타활동',
        key: '기타활동',
        width: '130px',
    },
    {
        title: '비고',
        dataIndex: '비고',
        key: '비고',
        width: '150px',
    },
]

const ListView = ({ list, setLoading, fetchList, form, isSubMaster, handleChangeSwitch, switchStates }) => {
    const { pathname, search } = useLocation()
    const paths = pathname.split('/').filter(path => path !== '')
    const navigate = useNavigate()
    const { isMaster, regionId } = useSelector(getUser)
    const { userInfo } = useSelector(getAllUserInfo)

    // 공통 삭제 모달
    const [isModalOpen, setIsModalOpen] = useState(false)
    // 공통 삭제 index
    const [deleteIndex, setDeleteIndex] = useState()
    // 계정 수정 모달
    const [isAccountModalOpen, setIsAccountModalOpen] = useState(false)
    // 계정 수정 userId
    const [editAccountInfo, setEditAccountInfo] = useState({
        userId: null,
        regionId: null,
    })

    const [messageApi, contextHolder] = message.useMessage()
    const success = 'success'
    const error = 'error'
    const login = 'login'

    const alertMessage = status => {
        messageApi.open({
            type: status === success ? success : error,
            content:
                status === login
                    ? '로그인 세션이 만료되었습니다.'
                    : status === success
                    ? `계정이 정상적으로 삭제되었습니다.`
                    : `계정 삭제 실패하었습니다.`,
            duration: 5,
        })
    }

    const handleLinkClick = (e, entity, entityId) => {
        const targetTagName = e.target.tagName
        if (
            paths[1] === 'register' ||
            targetTagName === 'BUTTON' ||
            targetTagName === 'svg' ||
            targetTagName === 'path'
        ) {
            e.preventDefault()
        } else {
            navigate(`${entity}/${entityId}`)
        }
    }

    const createClickHandler = (path, id, idx) => {
        return {
            onClick: e => {
                const isSurvey = path.includes('survey')
                const isSwitch = !!e.target?.className?.includes('switch')

                if (isSurvey && isSwitch) return

                handleLinkClick(e, path, id, idx)
            },
        }
    }

    let errorDisplayed = false

    const showError = errorMessage => {
        if (!errorDisplayed) {
            errorDisplayed = true
            message.error({
                content: errorMessage,
                key: 'uniqueError', // unique key for this message
                onClose: () => {
                    errorDisplayed = false
                }, // reset errorDisplayed when message is closed
            })
        }
    }

    const handleDeleteItem = (e, index) => {
        console.log('index : ', index)
        e.preventDefault()
        setIsModalOpen(true)
        setDeleteIndex(index)
    }

    /**
     * 삭제 공통 컴포넌트
     * @param e
     * @returns {Promise<void>}
     */
    const handleConfirmDelete = async e => {
        const item = list[deleteIndex]
        const searchPath = search.split('?')[1] || ''
        e.preventDefault()

        setLoading(true)
        if (paths[0] === 'program') {
            const response = await deleteProgramById(item.programId)
            console.log('response : ', response)
            await fetchList(searchPath)
        }
        if (paths[0] === 'tutors') {
            const response = await deleteTutorById(item.tutorId)
            console.log('response 22: ', response)
            await fetchList(searchPath)
        }
        if (paths[0] === 'survey') {
            console.log('item.surveyId : ', item.surveyId)
            const response = await deleteSurveyById(item.surveyId)
            console.log('response : ', response)
            await fetchList(searchPath)
        }
        if (paths[0] === 'accounts') {
            // const response = await getAccountById(item.userId)
            // const { user_uid } = response[0]
            const response = await deleteAccountById(item.userId)
            // 정상 삭제
            if (response.status === 204) {
                // const response = await deleteAccountEmailByIUid(user_uid)
                alertMessage(success)
                // TODO: 로그인 에러 값 찾아서 분기처리
            } else if (response.status === '로그인 해 주세요.') {
                alertMessage(login)
                navigate('/')
            } else {
                alertMessage(error)
            }
            await fetchList(searchPath)
        }
        if (paths[0] === 'report') {
            // 월간보고 삭제
            const response = await deleteReportById(item.reportId)
            if (response.status === 200) {
                // 해당 지역 월간보고 목록 카운트 확인
                const countResponse = await getReportListCount(regionId)
                console.log('countResponse : ', countResponse)

                // 해당 지역 당해 총사업비 Id값 확인
                // TODO:월간보고 삭제 시 길이가 0이면 총사업비 데이터 삭제
                // const totalByIdresponse = await getReportTotalById(regionId)
                // console.log('totalByIdresponse 🤔🤔: ', totalByIdresponse)
                // 월간보고 특이사항 삭제
                const response = await deleteReportIssueById(item.reportId)
                if (response.status === 200) {
                    alertMessage(success)
                } else {
                    alertMessage(error)
                }
                // TODO: 에러 분기처리
            } else if (response.error.message === '로그인 해 주세요.') {
                alertMessage(login)
                navigate('/')
            } else {
                alertMessage(error)
            }
            await fetchList(searchPath)
        }
        if (paths[0] === 'contact') {
            // const response = await getAccountById(item.userId)
            // const { user_uid } = response[0]
            const response = await deleteContactById(item.contactId)
            // 정상 삭제
            if (response.status === 204) {
                // const response = await deleteAccountEmailByIUid(user_uid)
                alertMessage(success)
                // TODO: 로그인 에러 값 찾아서 분기처리
            } else if (response.status === '로그인 해 주세요.') {
                alertMessage(login)
                navigate('/')
            } else {
                alertMessage(error)
            }
            await fetchList(searchPath)
        }

        setIsModalOpen(false)
        setLoading(false)
    }

    const handleEditAccount = (id, region) => {
        console.log()
        setEditAccountInfo({
            userId: id,
            regionId: region,
        })
    }

    const createItems = index => {
        const item = list[index]
        const generateEditLink = path => {
            let id = ''
            if (path === 'program') {
                id = item.programId
            } else if (path === 'tutors') {
                id = item.tutorId
            } else if (path === 'survey') {
                id = item.surveyId
            } else if (path === 'report') {
                id = item.reportId
            } else if (path === 'contact') {
                id = item.contactId
            }
            return `/${path}/edit/${id}`
        }

        const items = [
            {
                label: (
                    <Tooltip title="수정">
                        {/*{paths[0] !== 'accounts' ? (*/}
                        <Link to={generateEditLink(paths[0], index, list)}>
                            <span className={styles.edit_icon} />
                        </Link>
                        {/*) : (*/}
                        {/*    <button*/}
                        {/*        type="button"*/}
                        {/*        className="button-reset-type"*/}
                        {/*        onClick={() => handleEditAccount(item.userId, item.regionId)}*/}
                        {/*    >*/}
                        {/*        <span className={styles.edit_icon} />*/}
                        {/*    </button>*/}
                        {/*)}*/}
                    </Tooltip>
                ),
                key: '1',
            },
            {
                label: (
                    <Tooltip title="삭제">
                        <button type="button" className="button-reset-type" onClick={e => handleDeleteItem(e, index)}>
                            <span className={styles.delete_icon} />
                        </button>
                    </Tooltip>
                ),
                key: '2',
            },
        ]

        return items
    }

    const surveyDetailColumns = [
        {
            title: '신청기관 유형',
            dataIndex: 'isMeet',
            key: 'isMeet',
            render: item => <span>{item === null ? '해당없음' : item ? '오프라인' : '온라인'}</span>,
        },
        {
            title: '신청자/단체명',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <Link className={styles.txt_color} to={`/survey/response/${record.sourceOfDemandId}`}>
                    {record.name}
                </Link>
            ),
        },
        {
            title: '전화번호',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: '교육유형',
            dataIndex: 'organizationType',
            key: 'organizationType',
            render: item => <span>{item === null ? '해당없음' : item}</span>,
        },
        {
            title: '신규여부',
            dataIndex: 'isNew',
            key: 'isNew',
            render: item => <span>{item === null ? '해당없음' : item ? '신규' : '재등록'}</span>,
        },
        {
            title: '교육기간',
            dataIndex: 'period',
            key: 'period',
            render: item => <span>{item}</span>,
        },
        {
            title: '채택유무',
            dataIndex: 'isSelected',
            key: 'isSelected',
            render: (_, record, i) => {
                return (
                    <span>
                        <Switch
                            data-id={record.demanderId}
                            checked={switchStates[i]}
                            onChange={(checked, e) => handleChangeSwitch(checked, e, record, i)}
                        />
                    </span>
                )
            },
        },
    ]

    const programColumns = [
        {
            title: '번호',
            dataIndex: 'programId',
            key: 'programId',
            width: '100px',
        },
        {
            title: '구분',
            dataIndex: 'programBasicInfo',
            key: 'programBasicInfo',
            render: item => <span>{item.educationType}</span>,
        },
        {
            title: '대상기관명',
            dataIndex: 'programDemandAgency',
            key: 'programDemandAgency',
            width: '130px',
            render: item => <span>{item.organizationName}</span>,
        },
        {
            title: '학년',
            dataIndex: 'programDemandAgency',
            key: 'grade',
            width: '130px',
            render: item => <span>{item.grade}</span>,
        },
        {
            title: '교육구분',
            dataIndex: 'programBasicInfo',
            key: 'method',
            width: '120px',
            render: item => <span>{item.method}</span>,
        },
        {
            title: '프로그램명',
            dataIndex: 'programBasicInfo',
            key: 'programBasicInfo',
            width: '300px',
            render: item => <span>{item.programName}</span>,
        },
        {
            title: '운영상태',
            dataIndex: 'programOperationInfo',
            key: 'status',
            render: item => <span>{item.status}</span>,
        },
        {
            title: '교육시작일',
            dataIndex: 'programOperationInfo',
            key: 'startDate',
            width: '125px',
            render: item => <span>{item.startDate !== null ? dayjs(item.startDate).format('YYYY-MM-DD') : ''}</span>,
        },
        {
            title: '교육종료일',
            dataIndex: 'programOperationInfo',
            key: 'endDate',
            width: '125px',
            render: item => <span>{item.endDate !== null ? dayjs(item.endDate).format('YYYY-MM-DD') : ''}</span>,
        },
        {
            title: !isMaster ? '수정/삭제' : '',
            key: !isMaster ? 'action' : '',
            width: !isMaster ? '110px' : '0',
            onCell: () => ({
                onClick: e => {
                    e.preventDefault() // 클릭 이벤트 막기
                    e.stopPropagation() // 이벤트 버블링 막기
                },
            }),
            render: (id, record, index) => {
                if (!isMaster) {
                    const items = createItems(index)
                    return (
                        <Dropdown
                            menu={{
                                items,
                            }}
                        >
                            <button type="button" className="button-reset-type" style={{ width: '50px' }}>
                                <span className={styles.edit_btn} />
                            </button>
                        </Dropdown>
                    )
                }
                return null
            },
        },
    ]

    const tutorColumns = [
        {
            title: '번호',
            dataIndex: 'tutorId',
            key: 'tutorId',
            width: '100px',
        },
        {
            title: '강사명',
            dataIndex: 'tutorName',
            key: 'tutorName',
        },
        {
            title: '성별',
            dataIndex: 'gender',
            key: 'gender',
            width: '80px',
            render: (item, record) => {
                return <span>{item || getGenderFromResidentNumber(record.residentNum)}</span>
            },
        },
        {
            title: '나이',
            dataIndex: 'residentNum',
            key: 'residentNum',
            render: item => <span>{getAgeFromJumin(item)}</span>,
        },
        {
            title: '이메일',
            dataIndex: 'email',
            key: 'email',
            width: '180px',
        },
        {
            title: '최종학력',
            dataIndex: 'educationLevel',
            key: 'educationLevel',
        },
        {
            title: '학과',
            dataIndex: 'major',
            key: 'major',
        },
        {
            title: '고용형태',
            dataIndex: 'tutorContractInfo',
            key: 'tutorContractInfo',
            render: item => <span>{item.employmentType}</span>,
        },
        {
            title: !isMaster ? '수정/삭제' : '',
            key: !isMaster ? 'action' : '',
            width: !isMaster ? '110px' : '0',
            onCell: () => ({
                onClick: e => {
                    e.preventDefault() // 클릭 이벤트 막기
                    e.stopPropagation() // 이벤트 버블링 막기
                },
            }),
            render: (id, record, index) => {
                if (!isMaster) {
                    const items = createItems(index)
                    return (
                        <Dropdown
                            menu={{
                                items,
                            }}
                        >
                            <button type="button" className="button-reset-type" style={{ width: '50px' }}>
                                <span className={styles.edit_btn} />
                            </button>
                        </Dropdown>
                    )
                }
                return null
            },
        },
    ]

    // 계정관리 리스트
    const accountsColumns = [
        {
            title: '번호',
            dataIndex: 'userSeq',
            key: 'userSeq',
        },
        {
            title: '지역',
            dataIndex: 'regionId',
            key: 'regionId',
        },
        {
            title: '아이디',
            dataIndex: 'userId',
            key: 'userId',
        },
        {
            title: '계정타입',
            dataIndex: 'isServiceManager',
            key: 'isServiceManager',
            render: item => <span>{item ? '용역사용자' : '지역사용자'}</span>,
        },
        {
            title: '생성자',
            dataIndex: 'sysRegrId',
            key: 'sysRegrId',
        },
        {
            title: '생성일',
            dataIndex: 'sysRegDtime',
            key: 'sysRegDtime',
            render: item => <span>{item !== null ? dayjs(item).format('YYYY-MM-DD') : ''}</span>,
        },
        {
            title: '관리자 인증여부',
            dataIndex: 'auth_status',
            key: 'auth_status',
            render: item => <span>{item ? '인증 완료' : '인증 진행중'}</span>,
        },
        // {
        //     title: '최종수정일',
        //     dataIndex: 'sysModDtime',
        //     key: 'sysModDtime',
        //     render: item => <span>{item !== null ? dayjs(item).format('YYYY-MM-DD') : ''}</span>,
        // },
        {
            title: !isSubMaster ? '삭제' : '',
            key: !isSubMaster ? 'action' : '',
            width: !isSubMaster ? '110px' : '0',
            render: (id, record, index) => {
                // 수정도 필요할 때 -> 정책 변경됨 수정 기능 삭제
                // const items = createItems(index)
                if (isSubMaster) {
                    return null
                }

                const items = [
                    {
                        label: (
                            <Tooltip title="삭제">
                                <button
                                    type="button"
                                    className="button-reset-type"
                                    onClick={e => handleDeleteItem(e, index)}
                                >
                                    <span className={styles.delete_icon} />
                                </button>
                            </Tooltip>
                        ),
                        key: '1',
                    },
                ]

                return (
                    <Dropdown
                        menu={{
                            items,
                        }}
                    >
                        <button
                            type="button"
                            className="button-reset-type"
                            style={{ width: '50px' }}
                            onClick={e => handleDeleteItem(e, index)}
                        >
                            <span className={styles.edit_btn} />
                        </button>
                    </Dropdown>
                )
            },
        },
    ]

    // 월간보고 목록
    const reportColumns = [
        {
            title: '번호',
            dataIndex: 'reportId',
            key: 'reportId',
            width: '100px',
        },
        {
            title: '지역',
            dataIndex: 'regionId',
            key: 'regionId',
            width: '80px',
        },
        {
            title: '기관명',
            dataIndex: 'organizationName',
            key: 'organizationName',
            width: '200px',
        },
        // {
        //     title: '사업명',
        //     dataIndex: 'projectName',
        //     key: 'projectName',
        // },
        {
            title: '작성자',
            dataIndex: 'author',
            key: 'author',
            width: '100px',
        },
        {
            title: '작성월',
            dataIndex: 'monthOfReport',
            key: 'monthOfReport',
            width: '120px',
            render: item => <span>{item !== null ? dayjs(item).format('YYYY[년] MM[월]') : ''}</span>,
        },
        {
            title: '작성일',
            dataIndex: 'sysRegDtime',
            key: 'sysRegDtime',
            render: item => <span>{item !== null ? dayjs(item).format('YYYY[년] MM[월] DD[일]') : ''}</span>,
        },
        {
            title: '수정일',
            dataIndex: 'sysModDtime',
            key: 'sysModDtime',
            render: item => <span>{item !== null ? dayjs(item).format('YYYY[년] MM[월] DD[일]') : ''}</span>,
        },
        {
            title: !isMaster ? '수정/삭제' : '',
            key: !isMaster ? 'action' : '',
            width: !isMaster ? '110px' : '0',
            onCell: () => ({
                onClick: e => {
                    e.preventDefault() // 클릭 이벤트 막기
                    e.stopPropagation() // 이벤트 버블링 막기
                },
            }),
            render: (id, record, index) => {
                if (!isMaster) {
                    const items = createItems(index)
                    return (
                        <Dropdown
                            menu={{
                                items,
                            }}
                        >
                            <button type="button" className="button-reset-type" style={{ width: '50px' }}>
                                <span className={styles.edit_btn} />
                            </button>
                        </Dropdown>
                    )
                }
                return null
            },
        },
    ]
    //문의하기 목록

    const contactColumns = [
        { title: '번호', dataIndex: 'contactId', key: 'number', width: 100 },
        {
            title: '지역',
            dataIndex: 'regionId',
            key: 'regionId',
            width: '100px',
            render: item => <span>{item === 'all' ? '테스트' : item}</span>,
        },
        { title: '유형', dataIndex: 'contactCategory', key: 'contactCategory', width: 100, align: 'left' },
        { title: '제목', dataIndex: 'contactTitle', key: 'title', align: 'left' },
        {
            title: '작성일',
            dataIndex: 'createAt',
            key: 'createAt',
            width: 200,
            render: item => <span>{item !== null ? dayjs(item).format('YYYY.MM.DD') : ''}</span>,
        },
        {
            title: '첨부파일',
            dataIndex: 'contactAttachment',
            key: 'attachment',
            width: 150,
            render: contactAttachment => {
                // Check if contactAttachment is an array and has at least one item
                if (Array.isArray(contactAttachment) && contactAttachment.length > 0) {
                    return (
                        <Tooltip title="첨부파일 있음">
                            <Attachment />
                        </Tooltip>
                    )
                }

                // If contactAttachment is an empty array or not an array, return null (no icon)
                return null
            },
        },
        {
            title: '운영상태',
            dataIndex: 'contactStatus',
            key: 'status',
            width: 150,
            render: status => {
                let iconComponent
                switch (status) {
                    case 'time':
                        iconComponent = (
                            <Tooltip title="답변대기">
                                <PageIcon />
                            </Tooltip>
                        )
                        break
                    case 'admin':
                        iconComponent = (
                            <Tooltip title="답변완료">
                                <AdminIcon />
                            </Tooltip>
                        )
                        break
                    default:
                        iconComponent = null
                }
                return iconComponent
            },
        },
    ]
    // 교구재 재고 목록
    const toolcolumns = [
        {
            title: '교구번호',
            dataIndex: 'toolNumber',
            key: 'toolNumber',
        },
        {
            title: '지역',
            dataIndex: 'regionId',
            key: 'regionId',
        },
        {
            title: '교구명',
            dataIndex: 'toolInfo',
            key: 'toolInfo',
            render: item => <span>{item.toolName}</span>,
        },
        {
            title: '자산번호',
            dataIndex: 'assetNumber',
            key: 'assetNumber',
        },
        {
            title: '취득일',
            dataIndex: 'assetInfo',
            key: 'assetInfo',
            render: item => (
                <span>{item !== null ? dayjs(item.acquisitionDate).format('YYYY[년] MM[월] DD[일]') : ''}</span>
            ),
        },
        {
            title: '재고위치',
            dataIndex: 'inventoryLocation',
            key: 'inventoryLocation',
        },
        {
            title: '구매가격',
            dataIndex: 'assetInfo',
            key: 'assetInfo',
            render: item => <span>{item.purchasePrice}</span>,
        },
        {
            title: !isMaster ? '수정/삭제' : '',
            key: !isMaster ? 'action' : '',
            width: !isMaster ? '110px' : '0',
            render: (id, record, index) => {
                if (!isMaster) {
                    const items = createItems(index)
                    return (
                        <Dropdown
                            menu={{
                                items,
                            }}
                        >
                            <button type="button" className="button-reset-type" style={{ width: '50px' }}>
                                <span className={styles.edit_btn} />
                            </button>
                        </Dropdown>
                    )
                }
                return null
            },
        },
    ]

    const handleLinkRental = id => {
        navigate(`/tools/rental/transfer/${id}`)
    }

    // 교구재 대여반납 목록
    const rentalColumns = [
        {
            title: '대여번호',
            dataIndex: 'rentalId',
            key: 'rentalId',
        },
        {
            title: '지역',
            dataIndex: 'regionId',
            key: 'regionId',
        },
        {
            title: '대여자',
            dataIndex: 'renterName',
            key: 'renterName',
        },
        {
            title: '신청일',
            dataIndex: 'applicationDate',
            key: 'applicationDate',
            render: item => <span>{item !== null ? dayjs(item).format('YYYY-MM-DD') : ''}</span>,
        },
        {
            title: '대여일자',
            dataIndex: 'rentalDate',
            key: 'rentalDate',
            render: item => <span>{item !== null ? dayjs(item).format('YYYY-MM-DD') : ''}</span>,
        },
        {
            title: '반납일자',
            dataIndex: 'returnDate',
            key: 'returnDate',
            render: item => <span>{item !== null ? dayjs(item).format('YYYY-MM-DD') : ''}</span>,
        },
        {
            title: '진행',
            key: 'action',
            render: (id, record) => {
                const { rentalId } = record
                return (
                    <FilterButton
                        icon={<FinishFilterBottomIcon />}
                        title="반납"
                        onClick={() => handleLinkRental(rentalId)}
                        // currentParamValue="진행"
                        filterColor="darkgray"
                    />
                )
            },
        },
        {
            title: !isMaster ? '수정/삭제' : '',
            key: !isMaster ? 'action' : '',
            width: !isMaster ? '110px' : '0',
            render: (id, record, index) => {
                if (!isMaster) {
                    const items = createItems(index)
                    return (
                        <Dropdown
                            menu={{
                                items,
                            }}
                        >
                            <button type="button" className="button-reset-type" style={{ width: '50px' }}>
                                <span className={styles.edit_btn} />
                            </button>
                        </Dropdown>
                    )
                }
                return null
            },
        },
    ]

    // 교구재 대여반납 반출리스트
    const transferColumns = [
        {
            title: '선택',
            dataIndex: 'toolNumber',
            key: 'toolNumber',
            render: item => <CheckBox id={item} />,
        },
        {
            title: '교구번호',
            dataIndex: 'toolNumber',
            key: 'toolNumber',
        },
        {
            title: '교구명',
            dataIndex: 'toolName',
            key: 'toolName',
        },
        {
            title: '자산번호',
            dataIndex: 'assetId',
            key: 'assetId',
        },
        {
            title: '재고위치',
            dataIndex: 'stockLocation',
            key: 'renterName',
        },
    ]

    // 수요조사 목록
    const surveyColumns = [
        {
            title: '번호',
            dataIndex: 'surveyId',
            key: 'surveyId',
            width: '100px',
        },
        {
            title: '지역',
            dataIndex: 'regionId',
            key: 'regionId',
            width: '100px',
        },
        {
            title: '수요조사명',
            dataIndex: 'surveyName',
            key: 'surveyName',
        },
        {
            title: '작성년월',
            dataIndex: 'sysRegDtime',
            key: 'sysRegDtime',
            render: item => <span>{item !== null ? dayjs(item).format('YYYY[년] MM[월]') : ''}</span>,
        },
        {
            title: '시작일',
            dataIndex: 'startDate',
            key: 'startDate',
            render: item => <span>{item !== null ? dayjs(item).format('YYYY[년] MM[월] DD[일]') : ''}</span>,
        },
        {
            title: '마감일',
            dataIndex: 'endDate',
            key: 'endDate',
            render: item => <span>{item !== null ? dayjs(item).format('YYYY[년] MM[월] DD[일]') : ''}</span>,
        },
        // {
        //     title: !isMaster ? '수정/삭제' : '',
        //     key: !isMaster ? 'action' : '',
        //     width: !isMaster ? '110px' : '0',
        //     render: (id, record, index) => {
        //         if (!isMaster) {
        //             const items = createItems(index)
        //             console.log('items : ', items)
        //             return (
        //                 <Dropdown
        //                     menu={{
        //                         items,
        //                     }}
        //                 >
        //                     <button type="button" style={{ width: '50px' }}>
        //                         <span className={styles.edit_btn} />
        //                     </button>
        //                 </Dropdown>
        //             )
        //         }
        //         return null
        //     },
        {
            title: !isMaster ? '수정/삭제' : '',
            key: !isMaster ? 'action' : '',
            width: !isMaster ? '110px' : '0',
            onCell: () => ({
                onClick: e => {
                    e.preventDefault() // 클릭 이벤트 막기
                    e.stopPropagation() // 이벤트 버블링 막기
                },
            }),
            render: (id, record, index) => {
                if (!isMaster) {
                    const items = createItems(index)
                    return (
                        <Dropdown
                            menu={{
                                items,
                            }}
                        >
                            <button type="button" className="button-reset-type" style={{ width: '50px' }}>
                                <span className={styles.edit_btn} />
                            </button>
                        </Dropdown>
                    )
                }
                return null
            },
        },
    ]

    /**
     * URL path에 따라 리스트 형태 분기처리
     * @param pathTitle
     * @returns {[{dataIndex: string, title: string, key: string},{dataIndex: string, title: string, key: string},{dataIndex: string, title: string, key: string},{dataIndex: string, title: string, key: string},{dataIndex: string, title: string, key: string},null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null]|*|string|boolean|[{dataIndex: string, title: string, key: string},{dataIndex: string, title: string, key: string},{dataIndex: string, title: string, key: string},{dataIndex: string, title: string, key: string},{dataIndex: string, title: string, key: string},null]}
     */
    const handlePath = pathTitle => {
        if (pathTitle.length > 1) {
            const [firstTitle, secondTitle, thirdTitle] = pathTitle

            if (firstTitle === 'program' && secondTitle === 'register') {
                return programRegisterColumns
            }
            if (firstTitle === 'tutors' && secondTitle === 'register') {
                return tutorsRegisterColumns
            }
            if (firstTitle === 'survey') {
                return surveyDetailColumns
            }
            if (firstTitle === 'tools' && secondTitle === 'rental' && thirdTitle === 'transfer') {
                return transferColumns
            }
            if (firstTitle === 'tools' && secondTitle === 'inventory') {
                return toolcolumns
            }
            if (firstTitle === 'tools' && secondTitle === 'rental') {
                return rentalColumns
            }
        } else {
            const pathMap = {
                program: programColumns,
                tutors: tutorColumns,
                accounts: accountsColumns,
                report: reportColumns,
                survey: surveyColumns,
                contact: contactColumns,
            }

            return pathMap[pathTitle] || ''
        }
        return false
    }

    /**
     * 리스트 스크롤 width 공통 함수
     * @param pathTitle
     * @returns {number}
     */
    const getScrollX = pathTitle => {
        const [firstTitle, secondTitle, thirdTitle] = pathTitle

        if (pathTitle.length <= 1) {
            if (firstTitle === 'tutors' && secondTitle === 'register') {
                return 3000
            }
            return 1000
        }

        if (firstTitle === 'tools') {
            if (secondTitle === 'rental' && thirdTitle === 'transfer') {
                return 500
            }
            if (secondTitle === 'inventory' || secondTitle === 'rental') {
                return 1000
            }
        }

        if (firstTitle === 'survey') {
            return 1000
        }

        return 5000
    }

    // 계정 수정 userId 변경 시 modal 오픈
    useEffect(() => {
        if (editAccountInfo.userId !== null) {
            setIsAccountModalOpen(true)
        }
    }, [editAccountInfo])

    // 계정 수정 modal 닫으면 값 초기화
    useEffect(() => {
        if (isAccountModalOpen === false) {
            setEditAccountInfo({
                userId: null,
                regionId: null,
            })
            // form.resetFields()
        }
    }, [isAccountModalOpen])

    // useEffect(() => {
    //     if (!isAccountModalOpen) {
    //         form.resetFields()
    //     }
    // }, [isAccountModalOpen])

    return (
        <>
            {contextHolder}
            <div
                className={`${styles.container} ${
                    paths[1] && paths[0] === 'survey'
                        ? ''
                        : paths[0] === 'accounts' ||
                          paths[0] === 'report' ||
                          paths[0] === 'survey' ||
                          paths[0] === 'contact'
                        ? styles.section
                        : ''
                }`}
            >
                {paths[1] && paths[0] === 'survey' && <div className={styles.title}>수요처 목록</div>}
                <Table
                    className={styles.table}
                    dataSource={list}
                    scroll={{
                        x: getScrollX(paths),
                        y: 900,
                    }}
                    columns={handlePath(paths)}
                    pagination={false}
                    onRow={record => {
                        const { programId, tutorId, surveyId, toolNumber, reportId, demanderId, contactId, regionId } =
                            record || {}

                        const createClickHandlerWithCondition = (path, id, condition = true, errorMessage = '') => {
                            return {
                                onClick: e => {
                                    if (condition) {
                                        handleLinkClick(e, path, id)
                                    } else {
                                        showError(errorMessage)
                                    }
                                },
                            }
                        }

                        if (paths[0] === 'program' && programId) {
                            return createClickHandler('/program', programId)
                        }
                        if (paths[0] === 'tutors' && tutorId) {
                            return createClickHandler('/tutors', tutorId)
                        }
                        if (paths[0] === 'survey' && surveyId) {
                            return createClickHandler('/survey', surveyId)
                        }
                        if (paths[0] === 'survey' && demanderId) {
                            return createClickHandler('/survey/response', demanderId)
                        }
                        if (paths[0] === 'report' && reportId) {
                            return createClickHandler('/report', reportId)
                        }
                        if (paths[0] === 'contact') {
                            const condition =
                                contactId && (regionId === userInfo.regionId || userInfo.regionId === 'all')
                            return createClickHandlerWithCondition(
                                '/contact/view',
                                contactId,
                                condition,
                                '권한이 없습니다.',
                            )
                        }
                        if (paths[0] === 'tools') {
                            if (paths[1] === 'inventory' && toolNumber) {
                                return createClickHandler('/tools/inventory', toolNumber)
                            }
                            if (paths[1] === 'rental' && toolNumber) {
                                return createClickHandler('/tools/rental', toolNumber)
                            }
                        }
                        return false
                    }}
                />
                {/* 공통 삭제 Modal */}
                <InfoDialog
                    content={<DeleteModalContent />}
                    centered
                    modalOpen={isModalOpen}
                    setModalOpen={setIsModalOpen}
                    closable={false}
                    handleConfirm={handleConfirmDelete}
                    type={1}
                    isCancel
                />
                {/* 계정 수정 Modal */}
                {paths[0] === 'accounts' && (
                    <InfoDialog
                        content={
                            <Account
                                setModalOpen={setIsAccountModalOpen}
                                fetchList={fetchList}
                                type={2}
                                accountInfo={editAccountInfo}
                                form={form}
                            />
                        }
                        centered
                        modalOpen={isAccountModalOpen}
                        setModalOpen={setIsAccountModalOpen}
                        closable={false}
                        handleConfirm={handleEditAccount}
                        isCancel
                        submit
                        hideFooter
                        type={2}
                    />
                )}
            </div>
        </>
    )
}

export default ListView
