import { Route, Routes } from 'react-router-dom'

// import { lazy } from 'react'

import Main from 'pages/Main'
import Tutors from 'pages/Tutors'
import Login from 'pages/Login'
import RedirectPage from 'pages'
import Program from 'pages/Program'
import Report from 'pages/Report'
import Statistics from 'pages/Statistics'
import Contact from 'pages/Contact'
import ContactView from 'pages/Contact/View'
import ContactReWrite from 'pages/Contact/ReWrite'
import ContactWrite from 'pages/Contact/Write'

import TutorDetail from 'pages/Tutors/Detail'
import ProgramDetail from 'pages/Program/Detail'
import ProgramEdit from 'pages/Program/Edit'
import ProgramRegisterOne from 'pages/Program/Register/One'
import ProgramRegisterBulk from 'pages/Program/Register/Bulk'
import TutorRegisterBulk from 'pages/Tutors/Register/Bulk'
import TutorRegisterOne from 'pages/Tutors/Register/One'
import PageNotFound from 'pages/PageNotFound'
import TutorEdit from 'pages/Tutors/Edit'
import AccountsPage from 'pages/Accounts'
import ReportDetail from 'pages/Report/Detail'
import TotalStatistics from 'pages/Statistics/Total'

// import Survey from 'pages/Survey'
// import SurveyDetail from 'pages/Survey/Detail'
// import SurveyRegister from 'pages/Survey/Register'
// import SurveySubmit from 'pages/Survey/Submit'
// import SurveyEdit from 'pages/Survey/Edit'
// import SurveyResponse from 'pages/Survey/Response'

// import Tools from 'pages/Tools/Inventory'
// import ToolsDetail from 'pages/Tools/Inventory/Detail'
// import ToolsRentalDetail from 'pages/Tools/Rent/Detail'
// import ToolsEdit from 'pages/Tools/Inventory/Edit'
// import ToolsRentEdit from 'pages/Tools/Rent/Edit'
// import ToolsRental from 'pages/Tools/Rent'
// import RentalTransfer from 'pages/Tools/Transfer'
// import ToolRegister from 'pages/Tools/Inventory/Register'

const Router = () => {
    return (
        <Routes>
            <Route path="/main" element={<Main />} />
            <Route path="/login" element={<Login />} />
            <Route path="/program" element={<Program />} />
            <Route path="/program/:id" element={<ProgramDetail />} />
            <Route path="/program/edit/:id" element={<ProgramEdit />} />
            <Route path="/program/register/one" element={<ProgramRegisterOne />} />
            <Route path="/program/register/bulk" element={<ProgramRegisterBulk />} />
            <Route path="/tutors" element={<Tutors />} />
            <Route path="/tutors/:id" element={<TutorDetail />} />
            <Route path="/tutors/edit/:id" element={<TutorEdit />} />
            <Route path="/tutors/register/one" element={<TutorRegisterOne />} />
            <Route path="/tutors/register/bulk" element={<TutorRegisterBulk />} />

            <Route path="/report" element={<Report />} />
            <Route path="/report/:id" element={<ReportDetail />} />
            <Route path="/report/edit/:id" element={<ReportDetail />} />
            <Route path="/report/register" element={<ReportDetail />} />
            <Route path="/statistics" element={<Statistics />} />
            <Route path="/statistics/total" element={<TotalStatistics />} />

            {/* 문의하기 페이지 추가 */}
            <Route path="/contact" element={<Contact />} />
            <Route path="/contact/view/:id" element={<ContactView />} />
            <Route path="/contact/rewrite" element={<ContactReWrite />} />
            <Route path="/contact/write" element={<ContactWrite />} />

            {/*<Route path="/tools/inventory" element={<Tools />} />*/}
            {/*<Route path="/tools/inventory/:id" element={<ToolsDetail />} />*/}
            {/*<Route path="/tools/inventory/edit/:id" element={<ToolsEdit />} />*/}
            {/*<Route path="/tools/inventory/register" element={<ToolRegister />} />*/}
            {/*<Route path="/tools/rental/edit/:id" element={<ToolsRentEdit />} />*/}
            {/*<Route path="/tools/rental" element={<ToolsRental />} />*/}
            {/*<Route path="/tools/rental/:id" element={<ToolsRentalDetail />} />*/}
            {/*<Route path="/tools/rental/transfer/:id" element={<RentalTransfer />} />*/}

            {/*<Route path="/survey" element={<Survey />} />*/}
            {/*<Route path="/survey/:id" element={<SurveyDetail />} />*/}
            {/*<Route path="/survey/edit/:id" element={<SurveyEdit />} />*/}
            {/*<Route path="/survey/register" element={<SurveyRegister />} />*/}
            {/*<Route path="/survey/submit/:id" element={<SurveySubmit />} />*/}
            {/*<Route path="/survey/response/:id" element={<SurveyResponse />} />*/}
            <Route path="/accounts" element={<AccountsPage />} />
            <Route path="/" element={<RedirectPage />} />
            {/* 404 Not Found 페이지 */}
            <Route path="*" element={<PageNotFound />} />
        </Routes>
    )
}

export default Router
